<template>
  <div>
    <v-btn @click="dialog = !dialog" color="#b52787" dark style="display: block; margin: 0 auto">Subir nuevo pdf</v-btn>
    <v-dialog
        v-model="dialog">
      <v-card light class="pb-10">
        <v-card-title class=" darken-2">
          Nuevo pdf
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <label class="mr-5">Selecciona políticas de viaje</label>
              <input type="file" ref="file" multiple="multiple">
            </v-col>
            <v-btn
                class="mt-5"
                color="#d52787"
                dark
                @click="setFile">
              Continuar
            </v-btn>
            <v-btn class="ml-2 mt-5" outlined text @click="dialog=false">
              Atrás
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "NewFileForm",
  props:['refresh'],
data(){
    return{
      dialog:false
    }
},

  methods: {

    setFile() {
      this.loading = true;
      let url = this.base_url + "/admin/nuevopdf"
      let formData = new FormData();
      formData.set('tid',this.$route.params.id)
      if(this.$refs.file.files.length>0){
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          let file = this.$refs.file.files[i];
          formData.append('files[' + i + ']', file);
        }
      }
      this.axios.post(url, formData).then(() => {
        this.loading = false;
        this.dialog = false;
        this.refresh();
      });
    },
  }
}
</script>

<style scoped>
.v-slide-group__content {
  display: block !important;
}

</style>