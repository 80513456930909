var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"light":""}},[_c('v-card-title',[_c('h3',{staticStyle:{"color":"#d52787"}},[_vm._v("Historial de viajes")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"#d52787 !important","small":""},on:{"click":_vm.getData}},[_vm._v("mdi-reload")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","color":"#d52787","hide-details":"","label":"Buscar","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.errorInCharge)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Ha habido un error al obtener los datos, inténtalo de nuevo más tarde ")]):_vm._e(),_c('v-data-table',{attrs:{"footer-props":{
         'items-per-page-text':'Viajes por página'
       },"headers":_vm.headers,"items":_vm.travels,"loading":!_vm.loaded,"search":_vm.search,"loading-text":"Cargando... Por favor, espera","no-data-text":"Todavía no hay viajes activos"},scopedSlots:_vm._u([{key:"item.typus",fn:function(ref){
       var item = ref.item;
return [(item.typus==='1')?_c('span',[_vm._v("Bus")]):_vm._e(),(item.typus==='2')?_c('span',[_vm._v("Bus + Hotel")]):_vm._e(),(item.typus==='4')?_c('span',[_vm._v("Bus "),_c('b',[_vm._v("Club")])]):_vm._e(),(item.typus==='3')?_c('span',[_vm._v("Bus + Hotel "),_c('b',[_vm._v("Club")])]):_vm._e()]}},{key:"item.busType",fn:function(ref){
       var item = ref.item;
return [(item.busType==='1')?_c('span',{staticClass:"text-center"},[_vm._v("55 plazas")]):_vm._e(),(item.busType==='2')?_c('span',{staticClass:"text-center"},[_vm._v("63 plazas")]):_vm._e(),(item.busType==='3')?_c('span',{staticClass:"text-center"},[_vm._v("71 plazas")]):_vm._e()]}},{key:"item.price",fn:function(ref){
       var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" € ")]}},{key:"item.reservations",fn:function(ref){
       var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":Math.ceil((item.seats * 100) / item.places),"color":"pink","height":"20"}},[_c('strong',[_vm._v(_vm._s(Math.ceil((item.seats * 100) / item.places))+"%")])])]}},{key:"item.status",fn:function(ref){
       var item = ref.item;
return [(item.status==='1')?_c('span',{staticClass:"text-center"},[_vm._v("Activo")]):_vm._e(),(item.status==='2')?_c('span',{staticClass:"text-center"},[_vm._v("En Curso")]):_vm._e(),(item.status==='3')?_c('span',{staticClass:"text-center"},[_vm._v("Finalizado")]):_vm._e(),(item.status==='4')?_c('span',{staticClass:"text-center"},[_vm._v("Cancelado")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
       var item = ref.item;
return [_c('router-link',{attrs:{"to":'/viaje/'+item.travelID}},[_c('TooltipIcon',{attrs:{"icon":"see","text":"Ver viaje"}})],1),_c('TooltipIcon',{attrs:{"id":item.travelID,"type":"travel","reference":item.ref,"icon":"copy","text":"Copiar enlace","funct":_vm.showSnack}}),_c('TooltipIcon',{attrs:{"id":item.travelID,"reference":item.ref,"icon":"delete","text":"Borrar viaje","funct":_vm.getData}})]}}])},[_vm._v(" ¡ ")]),_c('NewTravelForm',{attrs:{"refresh":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }