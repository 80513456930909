<template>
  <v-card>
    <v-card-text>
      <v-row class="mb-5">
        <v-col cols="12" md="2">
          <v-text-field v-model="travel.ref" color="#d52787" label="Referencia"/>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="travel.busType" :items="busItems" color="#d52787" label="Típo de Bus" disabled/>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="travel.typus" :items="typeItems" color="#d52787" label="Típo de viaje" disabled/>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field type="date" v-model="travel.date" color="#d52787" label="Fecha inicio" />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field type="number" v-model="travel.duration" color="#d52787" label="Días de duración"
                        />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field type="number" v-model="travel.price" color="#d52787" label="Precio base"/>
        </v-col>
        <v-col cols="12" md="4" v-if="travel.typus==='2' || travel.typus==='3'">
          <v-checkbox v-model="indivRoom" true-value="'1'" color="#d52787"
                      label="Habitaciones Individuales" disabled/>
          <v-text-field v-if="indivRoom" prepend-icon="mdi-currency-eur" type="number"
                        v-model="travel.singleRoomPlus" color="#d52787" label="Suplemento Hab. Individual"
          />
        </v-col>
        <v-col cols="12" md="4" v-if="travel.typus==='2' || travel.typus==='3'">
          <v-checkbox v-model="doubleRoom" true-value="'1'" color="#d52787" label="Habitaciones Dobles" :disabled="doubleRoom"/>
          <v-text-field v-if="doubleRoom" prepend-icon="mdi-currency-eur" type="number"
                        v-model="travel.doubleRoomPlus" color="#d52787" label="Suplemento Hab. Doble"/>
        </v-col>
        <v-col cols="12" md="4" v-if="travel.typus==='2' || travel.typus==='3'">
          <v-checkbox v-model="tripleRoom" true-value="'1'" color="#d52787" label="Habitaciones Triples" :disabled="tripleRoom"/>
          <v-text-field v-if="tripleRoom" prepend-icon="mdi-currency-eur" type="number"
                        v-model="travel.tripleRoomPlus" color="#d52787" label="Suplemento Hab. Triple"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-img
              :aspect-ratio="16/9"
              :src="'https://kariba.autocarsgamon.com/images/uploads/'+travel.image"/>
        </v-col>
        <v-col cols="12" md="6" class="align-center justify-center">
          <h3>Reemplazar imagen</h3>
          <v-row>
            <v-col cols="12" md="4">
              <input type="file"  class="mt-5" ref="image" multiple="multiple">
            </v-col>
            <v-col cols="12" md="4">
              <v-btn @click="updateImage" :loading="updateLoading" class="mt-5" color="#b52787" dark
                     style="display: block; margin: 0 auto">Guardar imágen
              </v-btn>
            </v-col>
          </v-row>
          <h3>Certificado Covid-19</h3>
          <v-switch
              v-model="covid"
              label="¿Necesita el certificado Covid-19?"/>
          <h3>Términos y políticas</h3>
          <v-card
              class="ma-5"
              outlined
              v-for="file in files" v-bind:key="file.fileID">

            <v-list-item three-line>
              <v-list-item-content>
                <v-row>
                  <v-col cols="10">
                    <a :href="'https://kariba.autocarsgamon.com/pdf/'+file.name" target="_blank" class="pdfLink">
                      <div class="overline ">
                        <v-icon color="red" large>mdi-file-pdf-outline</v-icon>
                        {{ file.name }}
                      </div>
                    </a>
                  </v-col>
                  <v-col cols="2">
                    <v-icon color="black" @click="deleteFile(file.fileID)" large>mdi-close</v-icon>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

          </v-card>
          <new-file-form :refresh="refresh"/>
          <v-btn @click="updateInfo" :loading="updateLoading" class="mt-5" color="#b52787" dark
                 style="display: block; margin: 0 auto">Guardar cambios
          </v-btn>

        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NewFileForm from "./newFileForm";

export default {
  name: "travelInfo",
  components: {NewFileForm},
  props: ['travel', 'files', 'covid', 'refresh'],
  data() {
    return {
      loading: false,
      updateLoading: false,
      indivRoom:false,
      doubleRoom:false,
      tripleRoom:false,
      busItems: [{text: '55 Plazas', value: '1'}, {text: '63 Plazas', value: '2'}, {text: '71 Plazas', value: '3'}],
      typeItems: [{text: 'Bus', value: '1'}, {text: 'Bus + Hotel', value: '2'}, {text: 'Club', value: '3'}],
    }
  },
  mounted(){
    if(this.travel.singleRoom === "1")
      this.indivRoom = true;
   if(this.travel.doubleRoom === "1")
      this.doubleRoom = true;
   if(this.travel.tripleRoom === "1")
      this.tripleRoom = true;
  },
  methods: {
    deleteFile(fileID) {
      this.loading = true;
      let url = this.base_url + "/admin/quitarpdf"
      let formData = new FormData();
      formData.set('tid', this.$route.params.id)
      formData.set('fid', fileID)
      this.axios.post(url, formData).then(() => {
        this.loading = false;
        this.refresh();
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'delete travel pdf',
          properties:{version:this.version, fileID: fileID}
        });
      });
    },
    updateInfo() {
      this.updateLoading = true;
      let url = this.base_url + "/admin/actualizarinfo"
      let formData = new FormData();
      formData.set('tid', this.$route.params.id)
      formData.set('ref', this.travel.ref)
      formData.set('price', this.travel.price)
      formData.set('duration',this.travel.duration)
      formData.set('date',this.travel.date)
      formData.set('indiRoom',this.indivRoom)
      formData.set('doubleRoom',this.doubleRoom)
      formData.set('tripleRoom',this.tripleRoom)
      formData.set('supind', this.travel.singleRoomPlus)
      formData.set('supdob', this.travel.doubleRoomPlus)
      formData.set('suptrip', this.travel.tripleRoomPlus)
      formData.set('covid', this.covid)
      this.axios.post(url, formData).then(() => {
        this.updateLoading = false;
        this.refresh();
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'update travel info',
          properties:{version:this.version,travelID: this.$route.params.id, travel: this.travel,iRoom: this.indivRoom,dRoom: this.doubleRoom, tRoom: this.tripleRoom, covid:this.covid }
        });
      }).catch(e=>{
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'error whern update travel info',
          properties:{version:this.version,travelID: this.$route.params.id,error: e, travel: this.travel,iRoom: this.indivRoom,dRoom: this.doubleRoom, tRoom: this.tripleRoom, covid:this.covid }
        });
      });
    },
    updateImage(){
      let url = this.base_url + "/admin/actualizarImagen"
      let formData = new FormData();
      formData.set('tid', this.$route.params.id)
      if(this.$refs.image.files.length>0){
        for (let i = 0; i < this.$refs.image.files.length; i++) {
          let file = this.$refs.image.files[i];
          formData.append('images[' + i + ']', file);
        }
      }
      this.axios.post(url, formData).then(() => {
        this.refresh()
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'update travel image',
          properties:{version:this.version,travelID: this.$route.params.id }
        });
      })
    }
  }
}
</script>

<style scoped>
.pdfLink {
  text-decoration: none;
  color: black;
}

.overline:hover {
  font-weight: bold;
}

</style>