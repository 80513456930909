<template style="background: white">
  <v-card light class="mx-auto pt-5 pa-5" max-width="100%" :loading="!loaded">
    <v-card-title style="padding: 5px 0px 15px 0px !important; display: inline-block">
      <h3 style="color: #d52787">
        {{ travel.titleES }}
        <v-icon class="ml-2" color="#d52787 !important" small v-on:click="getData">mdi-reload</v-icon>
      </h3>
      <h4> Estado:
        <span v-if="files.length===0">Pendiente <span class="pendingDot"></span></span>
        <span v-else-if="travel.status==='1'">Activo <span class="activeDot"></span></span>
        <span v-else-if="travel.status==='2'">En Curso <span class="inCurseDot"></span></span>
        <span v-else-if="travel.status==='3'">Finalizado <span class="finishedDot"></span></span>
      </h4>
    </v-card-title>
    <v-alert
        v-if="errorInCharge"
        dense
        outlined
        type="error">
      Ha habido un error al obtener los datos, inténtalo de nuevo más tarde
    </v-alert>
    <v-row align="center" justify="center" v-if="loaded">
      <v-tabs
          color="#d52787"
          v-model="tab">
        <v-tab
            v-for="item in items"
            :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" light>
        <v-tab-item>
          <travel-info :travel="travel" :files="files" :covid="covid" :refresh="getData"/>
        </v-tab-item>
        <v-tab-item>
          <travel-texts :travel="travel" :refresh="getData"/>
        </v-tab-item>
        <v-tab-item>
          <travel-bus :travel="travel" :seats="seats"/>
        </v-tab-item>
        <v-tab-item>
          <travel-passengers :reservations="reservations" :refresh="getData"/>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-card>
</template>

<script>
// @ is an alias to /src
import TravelInfo from "../components/travel/travelInfo";
import TravelTexts from "../components/travel/travelTexts";
import TravelBus from "../components/travel/travelBus";
import TravelPassengers from "../components/travel/travelPassengers";

export default {
  name: 'Home',
  components: {
    TravelPassengers,
    TravelBus,
    TravelTexts,
    TravelInfo,
  },
  data() {
    return {
      search: '',
      errorInCharge: false,
      loaded: false,
      travel: null,
      seats: [],
      files: [],
      reservations: [],
      tab: null,
      covid:false,
      items: [
        'Editar Info', 'Editar Descripción', 'Gestión del bus', 'Reservas'
      ],
      bus: 1,
      type: 1,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let url = this.base_url + "admin/viaje/" + this.$route.params.id
      this.axios.get(url).then(response => {
        this.travel = response.data.travel;
        this.files = response.data.files;
        this.seats = response.data.seats;
        this.covid = response.data.travel.covid_certificate !== '0';
        this.reservations = response.data.reservations;
        this.type = parseInt(response.data.travel.typus);
        this.loaded = true;
        this.$ma.trackEvent({
          category: ' travel',
          action: 'Get travel info',
          properties:{version:this.version, travelID: this.$route.params.id}
        });
      });
    },
  }
}
</script>
<style scoped>
.v-slide-group__content {
  display: block !important;
}
.pendingDot {
  height: 15px;
  width: 15px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}
.activeDot {
  height: 15px;
  width: 15px;
  background-color: lightgreen;
  border-radius: 50%;
  display: inline-block;
}
.inCurseDot {
  height: 15px;
  width: 15px;
  background-color: lightskyblue;
  border-radius: 50%;
  display: inline-block;
}
.finishedDot{
  height: 15px;
  width: 15px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
}
</style>
