<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="10">
          <h4>Título
            <country-flag v-if="lang===1" country='es' size='small'/>
            <country-flag v-else country='es-CA' size='small'/>
          </h4>
          <v-text-field v-if="lang===1" v-model="travel.titleES" color="#d52787"/>
          <v-text-field v-else v-model="travel.titleCAT" color="#d52787"/>
        </v-col>
        <v-col cols="12" md="2">
          <v-select :items="langItems" v-model="lang" label="Idioma" color="#b52787">
            <template slot="selection" slot-scope="data">
              <country-flag v-if="lang===1" :country='data.item.ISO' size='normal'/>
              {{ data.item.text }}
            </template>
            <template slot="item" slot-scope="data">
              <country-flag :country='data.item.ISO' size='normal'/>
              {{ data.item.text }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <h4>Descripción
            <country-flag v-if="lang===1" country='es' size='small'/>
            <country-flag v-else country='es-CA' size='small'/>
          </h4>
          <vue-editor v-if="lang===1" v-model="travel.descriptionES"/>
          <vue-editor v-else v-model="travel.descriptionCAT"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="#b52787" dark style="display: block; margin: 0 auto" :loading="loading" @click="updateTexts">Guardar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "travelTexts",
  props: ['travel', 'refresh'],
  data() {
    return {
      loading:false,
      lang: 1,
      langItems: [{"text": "Castellano", "value": 1, "ISO": "es"}, {"text": "Català", "value": 2, "ISO": "es-CA"}],
    }
  },
  methods:{
    updateTexts(){
      this.loading = true;
      let url = this.base_url + "/admin/actualizartextos"
      let formData = new FormData();
      formData.set('tid',this.$route.params.id)
      formData.set('titleES', this.travel.titleES);
      formData.set('titleCAT', this.travel.titleCAT);
      formData.set('descES',this.travel.descriptionES);
      formData.set('descCAT',this.travel.descriptionCAT);
      this.axios.post(url, formData).then(() => {
        this.loading = false;
        this.refresh();
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'update travel description',
          properties:{version:this.version,travelID: this.$route.params.id, titleES: this.travel.titleES, titleCAT: this.travel.titleCAT, descES: this.travel.descriptionES, descCAT: this.travel.descriptionCAT}
        });
      });
    }
  }
}
</script>

<style scoped>

</style>