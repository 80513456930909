<template>
  <v-card>
    <v-card-text>
      <v-chip-group
          :multiple="true"
          active-class="deep-purple accent-4 white--text"
          column
          style="display: block;">
        <v-progress-circular
            v-if="!loaded"
            indeterminate
            color="#d52787"/>
        <div v-if="travel.busType==='1' && loaded" style="display: block; margin: 0 auto;">
          <div class="line1" v-for="busLine in bus55" v-bind:key="busLine.line">
            <div class="seats2" :style="'margin-left: '+calculateMargin(busLine.line)">
              <v-tooltip top v-for="busseat in busLine.seats" v-bind:key="busseat">
                <template #activator="{ on }">
                  <div v-on="on" class="d-inline-block">
                    <v-chip-group active-class="selectedSeat">
                      <v-chip
                          v-bind:class="{selectedSeat : isKaribaSeat(busseat)}"
                          @click="selectSeat(busseat)"
                          v-bind:disabled="isDisableSeat(busseat)">
                        <img :src="getBusSeatImg(busseat)" height="20" :alt="busseat"/>
                      </v-chip>
                    </v-chip-group>
                  </div>
                </template>
                <span>{{ getBusSeat(busseat) }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div v-if="travel.busType==='2' && loaded" style="display: block; margin: 0 auto;">
          <div class="line1" v-for="busLine in bus63" v-bind:key="busLine.line">
            <div class="seats2" :style="'margin-left: '+calculateMargin(busLine.line)">
              <v-tooltip top v-for="busseat in busLine.seats" v-bind:key="busseat">
                <template #activator="{ on }">
                  <div v-on="on" class="d-inline-block">
                    <v-chip-group active-class="selectedSeat">
                      <v-chip
                          v-bind:class="{selectedSeat : isKaribaSeat(busseat)}"
                          @click="selectSeat(busseat)"
                          v-bind:disabled="isDisableSeat(busseat)">
                        <img :src="getBusSeatImg(busseat)" height="20" :alt="busseat"/>
                      </v-chip>
                    </v-chip-group>
                  </div>
                </template>
                <span>{{ getBusSeat(busseat) }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div v-if="travel.busType==='3' && loaded" style="display: block; margin: 0 auto;">
          <div class="line1" v-for="busLine in bus71" v-bind:key="busLine.line">
            <div class="seats2" :style="'margin-left: '+calculateMargin(busLine.line)">
              <v-tooltip top v-for="busseat in busLine.seats" v-bind:key="busseat">
                <template #activator="{ on }">
                  <div v-on="on" class="d-inline-block">
                    <v-chip-group active-class="selectedSeat">
                      <v-chip
                          v-bind:class="{selectedSeat : isKaribaSeat(busseat)}"
                          @click="selectSeat(busseat)"
                          v-bind:disabled="isDisableSeat(busseat)">
                        <img :src="getBusSeatImg(busseat)" height="20" :alt="busseat"/>
                      </v-chip>
                    </v-chip-group>
                  </div>
                </template>
                <span>{{ getBusSeat(busseat) }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-chip-group>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "travelBus",
  props:['travel','seats'],
  data(){
    return{
      loaded:true,
      bus55: [
        {line: 1, seats: ["puerta", 4, 8, 12, 16, 20, 24, 28, "puerta", 34, 38, 42, 46, 50, 55]},
        {line: 2, seats: ["reservado", 3, 7, 11, 15, 19, 23, 27, "puerta", 33, 37, 41, 45, 49, 54]},
        {line: 3, seats: [53]},
        {line: 4, seats: [2, 6, 10, 14, 18, 22, 26, 30, 32, 36, 40, 44, 48, 52]},
        {line: 5, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 31, 35, 39, 43, 47, 51]},
      ],
      bus63: [
        {line: 6, seats: ["puerta", 3, 7, 11, 15, 19, 23, 27, "puerta", 31, 35, 39, 43, 47, 51, 55, 59]},
        {line: 7, seats: ["reservado", 4, 8, 12, 16, 20, 24, 28, "puerta", 32, 36, 40, 44, 48, 52, 56, 60]},
        {line: 8, seats: [63]},
        {line: 9, seats: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 42, 46, 50, 54, 58, 62]},
        {line: 10, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41, 45, 49, 53, 57, 61]},
      ],
      bus71: [
        {line: 11, seats: ["puerta", 4, 8, 12, 16, 20, 24, 28, 32, "puerta", 36, 42, 46, 50, 54, 58, 62, 66, 71]},
        {line: 12, seats: ["reservado", 3, 7, 11, 15, 19, 23, 27, 31, "puerta", 35, 41, 45, 49, 53, 57, 61, 65, 70]},
        {line: 13, seats: [69]},
        {line: 14, seats: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 40, 44, 48, 52, 56, 60, 64, 68]},
        {line: 15, seats: ["conductor", 1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 39, 43, 47, 51, 55, 59, 63, 67]},
      ],
    }
  },
  methods:{
    getBusSeatImg(busseat) {
      const base = "/assets/css/images/bus/";
      switch (busseat) {
        case 'puerta':
          return base + 'door.png';
        case 'conductor':
          return base + 'driver.png';
        case 'ocupado':
          return base + 'busyseat.png';
        default:
          return base + 'seat.png';
      }
    },
    getBusSeat(busseat) {
      let found = false;
      let foundseat = [];
      this.seats.forEach(seat => {
        if (parseInt(seat.seatID) == busseat) {
          found = true;
          foundseat = seat
        }
      })
      if (found)
        return busseat + ' - ' + foundseat.name
      else
        return busseat
    },
    isKaribaSeat(busseat) {
      let found = false;
      this.seats.forEach(seat => {
        if (parseInt(seat.seatID) == busseat)
          if (seat.name == 'kariba')
            found = true;
      })
      return found;
    },
    isDisableSeat(busseat) {
      if (busseat === 'puerta' || busseat === 'conductor' || busseat === 'reservado') return true;
      else {
        let found = false;
        this.seats.forEach(seat => {
          if (parseInt(seat.seatID) == busseat && seat.name != 'kariba')
            found = true;
        })
        return found
      }
    },
    selectSeat(sid) {
      this.loaded = false;
      let url = this.base_url + "/admin/reservarAsiento"
      let formData = new FormData();
      formData.set('tid', this.travel.travelID)
      formData.set('seatid', sid);
      this.axios.post(url, formData).then(response => {
        this.seats = response.data.seats;
        this.loaded = true
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'update seats',
          properties:{version:this.version,travelID: this.$route.params.id, seatID: sid }
        });
      });
    },
    calculateMargin(line) {
      switch (line) {
        case 1:
          return "3.75em";
        case 2:
          return "3.75em";
        case 3:
          return "56em";
        case 4:
          return "7.75em";
        case 5:
          return "4em";
        case 6:
          return "3.75em";
        case 7:
          return "3.75em";
        case 8:
          return "63.25em";
        case 9:
          return "7.75em";
        case 10:
          return "4em";
        case 11:
          return "2.75em";
        case 12:
          return "2.75em";
        case 13:
          return "69.5em";
        case 14:
          return "6.75em";
        case 15:
          return "3em";
      }}
  }
}
</script>

<style scoped>
.selectedSeat {
  background: #b52787 !important;
}
</style>
<style>
.v-chip--disabled {
  background: rgba(213, 39, 135, 0.5) !important;
}
</style>