<template>
  <v-card width="1920">
    <v-card-title>
      <v-col cols="6">
        <v-text-field
            color="#b52787"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar viajero"
            single-line
            hide-details/>
      </v-col>
      <v-col cols="3">
        <a :href="excelURL" target="_blank">
          <v-btn color="#b52787" dark>
            <v-icon
                right
                dark
                class="mr-2">
              mdi-cloud-upload
            </v-icon>
            Exportar en excel
          </v-btn>
        </a>
      </v-col>
    </v-card-title>
    <v-data-table
        :items-per-page="20"
        no-results-text="Todavía no hay viajeros"
        loading-text="Cargando..."
        :footer-props="{
           'items-per-page-text':'viajeros por página'
        }"
        :headers="headers"
        :items="reservations"
        :search="search">
      <template v-slot:item.phone="{ item }">
        <span v-if="item.phone !== '0'">{{ item.phone }}</span>
        <span v-else>--</span>
      </template>
      <template v-slot:item.confirmed="{item}">
        <v-chip v-if="item.confirmed == 1"
            class="ma-2"
            color="green"
            text-color="white">
          Pagado
        </v-chip>
        <v-chip
            v-else-if="item.canceled==1"
            class="ma-2"
            color="red"
            text-color="white">
          Error en pago
        </v-chip>
        <v-chip
          v-else-if="item.paymentError==1"
          class="ma-2"
          color="red"
          text-color="white">
          Tarjeta rechazada
        </v-chip>
        <v-chip
            v-else-if="item.tpv==1"
            class="ma-2"
            color="blue"
            text-color="white">
          En Proceso TPV
        </v-chip>
        <v-chip
            v-else-if="item.canceled==0 && item.tpv ==0 && item.paymentError==0 && item.confirmed ==0"
            class="ma-2"
            color="red"
            text-color="white">
          Pendiente de pago
        </v-chip>
      </template>
      <template v-slot:item.actions="{item}">
        <TooltipIcon v-if="item.canceled == 0 && item.tpv ==0 && item.confirmed == 0" :id="item.travelID"  type="payment" :reference="item.ref" :url="'https://kariba.autocarsgamon.com/errorenpago/'+item.tref+'/'+item.rid"  icon="copy" text="Copiar enlace" :funct="showSnack" />
        <TooltipIcon :id="item.rid" :reference="item.ref" icon="delres" text="Borrar reserva"
                     :funct="removeReservation"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import TooltipIcon from "../general/TooltipIcon";

export default {
  name: "travelPassengers",
  components: {TooltipIcon},
  props: ['reservations','refresh'],
  data() {
    return {
      search: '',
      excelURL: '',
      headers: [
        {
          text: 'Nombre y apellidos',
          align: 'start',
          value: 'name',
        },
        {text: 'DNI/NIF', value: 'dni'},
        {text: 'Teléfono', value: 'phone'},
        {text: 'Referencia', value: 'ref'},
        {text: 'E-Mail', value: 'email'},
        {text: 'Asiento', value: 'seat'},
        {text: 'Identificador TPV', value: 'redsisOrder'},
        {text: 'Fecha', value: 'date_add'},
        {text: 'Confirmado', value: 'confirmed'},
        {text: 'Acciones', value: 'actions'}
      ],
    }
  },
  methods: {
    removeReservation(rid) {
      let url = this.base_url + "/admin/borrarreserva/" + rid
      this.axios.get(url).then(() => {
        this.refresh();
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'delete reservation',
          properties:{version:this.version,travelID: this.$route.params.id, reservationID: rid }
        });
      });
    },
    showSnack() {
      this.snackbar = true
    }
  },
  mounted() {
    this.excelURL = this.base_url + "/admin/generateExcel.php?tid=" + this.$route.params.id;
  }
}
</script>