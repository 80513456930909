var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"1920"}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"color":"#b52787","append-icon":"mdi-magnify","label":"Buscar viajero","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('a',{attrs:{"href":_vm.excelURL,"target":"_blank"}},[_c('v-btn',{attrs:{"color":"#b52787","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-upload ")]),_vm._v(" Exportar en excel ")],1)],1)])],1),_c('v-data-table',{attrs:{"items-per-page":20,"no-results-text":"Todavía no hay viajeros","loading-text":"Cargando...","footer-props":{
         'items-per-page-text':'viajeros por página'
      },"headers":_vm.headers,"items":_vm.reservations,"search":_vm.search},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
      var item = ref.item;
return [(item.phone !== '0')?_c('span',[_vm._v(_vm._s(item.phone))]):_c('span',[_vm._v("--")])]}},{key:"item.confirmed",fn:function(ref){
      var item = ref.item;
return [(item.confirmed == 1)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" Pagado ")]):(item.canceled==1)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Error en pago ")]):(item.paymentError==1)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Tarjeta rechazada ")]):(item.tpv==1)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue","text-color":"white"}},[_vm._v(" En Proceso TPV ")]):(item.canceled==0 && item.tpv ==0 && item.paymentError==0 && item.confirmed ==0)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Pendiente de pago ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(item.canceled == 0 && item.tpv ==0 && item.confirmed == 0)?_c('TooltipIcon',{attrs:{"id":item.travelID,"type":"payment","reference":item.ref,"url":'https://kariba.autocarsgamon.com/errorenpago/'+item.tref+'/'+item.rid,"icon":"copy","text":"Copiar enlace","funct":_vm.showSnack}}):_vm._e(),_c('TooltipIcon',{attrs:{"id":item.rid,"reference":item.ref,"icon":"delres","text":"Borrar reserva","funct":_vm.removeReservation}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }